import { ApplicationConfig, ErrorHandler, PLATFORM_ID, inject, isDevMode, provideAppInitializer } from '@angular/core';
import { Router, provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  initializeAppCheck,
  provideAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@angular/fire/app-check';
import { environment } from 'src/environments/environment';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideServiceWorker } from '@angular/service-worker';
import { createErrorHandler, TraceService } from '@sentry/angular'
import { provideToastr } from 'ngx-toastr';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { isPlatformServer } from '@angular/common';
import { AgentEffects } from './shared/store/agent/+state/agent.effects';
import * as fromAgent from './shared/store/agent/+state/agent.reducer';
import { AgencyEffects } from './shared/store/agency/+state/agency.effects';
import * as fromAgency from './shared/store/agency/+state/agency.reducer';
import { EmployeeEffects } from './shared/store/employee/+state/employee.effects';
import * as fromEmployee from './shared/store/employee/+state/employee.reducer';
import { DialerAgentEffects } from './shared/store/dialer-agent/+state/dialer-agent.effects';
import * as fromDialerAgent from './shared/store/dialer-agent/+state/dialer-agent.reducer';
import { ConsumerEffects } from './shared/store/consumer/+state/consumer.effects';
import * as fromConsumer from './shared/store/consumer/+state/consumer.reducer';
import { LeadCallEffects } from './shared/store/lead-call/+state/lead-call.effects';
import * as fromLeadCall from './shared/store/lead-call/+state/lead-call.reducer';
import { PermissionEffects } from './shared/store/permission/permission.effects';
import * as fromPermission from './shared/store/permission/permission.reducer';
import { PermissionGrantedEffects } from './shared/store/permission-granted/+state/permission-granted.effects';
import * as fromPermissionGranted from './shared/store/permission-granted/+state/permission-granted.reducer';
import { PublisherEffects } from './shared/store/publisher/+state/publisher.effects';
import * as fromPublisher from './shared/store/publisher/+state/publisher.reducer';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

const provideSentryErrorHandler = (opts = {}) => {
  return {
    provide: ErrorHandler,
    useValue: createErrorHandler(opts),
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      })
    ),

    provideAnimations(),
    provideHttpClient(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() =>
      initializeFirestore(getApp(), { ignoreUndefinedProperties: true })
    ),
    provideFunctions(() => getFunctions(undefined, 'us-east1')),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    //@ts-expect-error - Initialize only on client
    provideAppCheck(() => {
      const platformId = inject(PLATFORM_ID);
      if (isPlatformServer(platformId)) {
        return;
      }
      const provider = new ReCaptchaEnterpriseProvider(
        environment.firebase.appCheckRecaptchaSiteKey
      );
      return initializeAppCheck(undefined, {
        provider,
        isTokenAutoRefreshEnabled: true,
      });
    }),
    provideStore({
      [fromAgent.agentFeatureKey]: fromAgent.reducer,
      [fromAgency.agencyFeatureKey]: fromAgency.reducer,
      [fromConsumer.consumerFeatureKey]: fromConsumer.reducer,
      [fromDialerAgent.dialerAgentFeatureKey]: fromDialerAgent.reducer,
      [fromEmployee.employeeFeatureKey]: fromEmployee.reducer,
      [fromLeadCall.leadCallFeatureKey]: fromLeadCall.reducer,
      [fromPermission.permissionFeatureKey]: fromPermission.reducer,
      [fromPermissionGranted.permissionGrantedFeatureKey]: fromPermissionGranted.reducer,
      [fromPublisher.publisherFeatureKey]: fromPublisher.reducer,
    }),
    provideEffects([
      AgentEffects,
      AgencyEffects,
      ConsumerEffects,
      DialerAgentEffects,
      EmployeeEffects,
      LeadCallEffects,
      PermissionEffects,
      PermissionGrantedEffects,
      PublisherEffects,
    ]),
    !environment.production ? provideStoreDevtools() : [],
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideSentryErrorHandler({
      showDialog: false,
    }),
    {
      provide: TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
    provideToastr(),
    AuthGuard,
  ],
};
